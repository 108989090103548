import React from 'react'
//need to set this to route jon-meier.com/aboutme
const AboutMe = () =>{
    return(
        <div id='aboutContent'>
            Hey! I'm Jon Meier, welcome to my portfolio
            <p>I'm a software developer with a background in small business management</p>
            <p>My passions are being disappointed by the Knicks, the Jets, and developing applications for everyday needs</p>            


        </div>
    )
}

export default AboutMe