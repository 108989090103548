import React from 'react'

const Resume = () => {
    return(
        <div>
        <p>
        Tech Stack
        </p>
        </div>

    )
}

export default Resume