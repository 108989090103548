import React from 'react'

const Projects = () =>{

    return(
        <div id='projectContent'>
           Some Projects I've Worked On: 
            {/* //I want to place my projects here
            //brief description of each project
            //maybe a quick screen capture of the project UI
            //hyperlink the title and the image to go to the website */}


        </div>
    )
}

export default Projects